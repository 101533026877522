<template>
  <ObiText class="obi-select" :class="{ 'is-invalid': errors.length }">
    <label
      :for="id || defaultId"
      v-html="label"
      v-if="label"
      class="form-label"
      :class="labelClass"
    />
    <ObiText
      class="input-group"
      :class="[{ 'mb-3': !compact, 'input-group-clean': clean }, wrapperClass]"
    >
      <ObiText
        class="obi-select-icon input-group-text input-group-icon"
        @click="simulateClick"
        v-if="icon && !iconRight"
      >
        <ObiIcon :icon="icon" />
      </ObiText>

      <select
        v-bind="attrs"
        ref="selectElement"
        class="form-control"
        v-model="inputValue"
        :id="id || defaultId"
        :class="{ 'is-invalid': errors.length, 'bg-transparent': transparent }"
      >
        <option
          v-for="(option, index) in options"
          :key="index"
          :value="option.value"
        >
          <slot name="option">{{ option.label }}</slot>
        </option>
      </select>
    </ObiText>

    <ObiText class="invalid-feedback" v-if="errors.length">
      <ObiText
        class="invalid-feedback-line"
        v-for="(error, index) in errors"
        :key="index"
        v-html="error"
      />
    </ObiText>
  </ObiText>
</template>

<script>
import uuid from 'uuidjs'
import { get, keys, omit } from 'lodash'

import IconChevronDown from '@/components/Icons/general/IconChevronDown.svg'

export default {
  name: 'ObiSelect',

  props: {
    value: {},
    id: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: [String, Object],
      default: null,
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    wrapperClass: {
      type: String,
      default: null,
    },
    labelClass: {
      type: String,
      default: null,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    clean: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    transparent: {
      type: Boolean,
      default: false,
    },
  },

  beforeMount() {
    this.defaultId = get(uuid.genV4(), 'hexNoDelim')
  },

  computed: {
    attrs() {
      return omit(this.$attrs, keys(this.$props))
    },
    inputValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },

  data() {
    return {
      IconChevronDown,
      defaultId: null,
    }
  },

  methods: {
    simulateClick() {
      this.$refs.selectElement.click()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables.scss';

.obi-select {
  .form-label {
    color: $secondary;
  }

  option {
    background-color: var(--background-color);
  }

  .form-control[readonly] {
    background-color: transparent;
    color: $secondary;
  }

  &.is-invalid {
    .input-group-text {
      border-color: $form-feedback-invalid-color;
    }
  }

  &.is-valid {
    .input-group-text {
      border-color: $form-feedback-valid-color;
    }
  }

  .invalid-feedback {
    text-align: left;
  }

  .input-group {
    .form-control {
      border-radius: 4px !important;
      color: $secondary;

      + .input-group-text.input-group-icon {
        right: 0;
        left: auto;
      }
    }

    .input-group-text.input-group-icon {
      left: 0;
      z-index: 9999;
      position: absolute;
      pointer-events: none;

      + .form-control {
        padding-right: 0;
        padding-left: 3.5rem;
      }
    }
  }
}
</style>
